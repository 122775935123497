// src/App.js

import React from 'react';
import SearchForm from './components/SearchForm';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Suche</h1>
                <SearchForm />
            </header>
        </div>
    );
}

export default App;
