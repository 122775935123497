// src/components/SearchForm.js

import React, { useState } from 'react';

function SearchForm() {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('http://127.0.0.1:5000/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ search_query: query })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setResult(data);
        } catch (error) {
            console.error('Failed to fetch', error);
        } finally {
            setLoading(false);
        }
    };

    const renderResult = () => {
        if (!result) return null;

        return (
            <div className="bg-gray-800 p-6 rounded-lg mt-6 transition-transform transform hover:scale-105">
                <h2 className="text-xl font-semibold mb-4">Ergebnis:</h2>
                <p><strong>Anzahl der Datenbanken:</strong> {result.NumOfDatabase}</p>
                <p><strong>Anzahl der Ergebnisse:</strong> {result.NumOfResults}</p>
                <p><strong>Freie Anfragen übrig:</strong> {result.free_requests_left}</p>
                <p><strong>Preis:</strong> {result.price}</p>
                <p><strong>Suchzeit:</strong> {result['search time']} Sekunden</p>
                
                {Object.keys(result.List).map((source, index) => (
                    <div key={index} className="mt-6">
                        <h3 className="text-lg font-semibold">{source}</h3>
                        <p><strong>InfoLeak:</strong> {result.List[source].InfoLeak}</p>
                        <p><strong>Anzahl der Ergebnisse:</strong> {result.List[source].NumOfResults}</p>
                        <table className="table-auto w-full mt-4">
                            <thead>
                                <tr>
                                    {Object.keys(result.List[source].Data[0]).map((key) => (
                                        <th key={key} className="px-4 py-2 border">{key}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {result.List[source].Data.map((entry, idx) => (
                                    <tr key={idx} className="transition-colors hover:bg-gray-700">
                                        {Object.values(entry).map((value, subIdx) => (
                                            <td key={subIdx} className="px-4 py-2 border">{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <form onSubmit={handleSubmit} className="bg-gray-900 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                <label className="block mb-4">
                    <span className="text-gray-300 block mb-2">Nach was soll gesucht werden?</span>
                    <input
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        required
                        className="mt-1 block w-full p-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-300"
                    />
                </label>
                <button type="submit" className="w-full py-2 px-4 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg">
                    {loading ? 'Lädt...' : 'Suchen'}
                </button>
            </form>
            {loading && (
                <div className="flex justify-center mt-4">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
            {renderResult()}
        </div>
    );
}

export default SearchForm;
